import { size, colors, typography, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';
import { SectionBackgroundType } from 'utils/types';

export const Container = styled.section<{
  backgroundType?: SectionBackgroundType;
}>`
  background-color: ${({ backgroundType }) =>
    backgroundType === SectionBackgroundType.WithMargin ? colors.green1 : ''};
  padding: ${size.sm}px 0;

  ${mediaQueries.forTabletHorizontalUp} {
    padding: ${size.xl4}px 0;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.p`
  ${typography.overlineText};
  margin-bottom: ${size.sm}px;
  text-transform: uppercase;
  text-align: left;
  font-weight: ${typography.weight.xbold};

  ${mediaQueries.forTabletHorizontalUp} {
    text-align: center;
  }
`;

export const Tab = styled.ul`
  display: flex;
  list-style: none;
  height: ${size.xl2}px;
  box-shadow: 0 0 0 1px ${colors.green4};

  /* Size + 1 as workaround for this https://stackoverflow.com/questions/72447503/css-rounded-border-leaves-a-gap-between-border-and-content */
  border-radius: ${Number(size.md) + 1}px;
  background-color: ${colors.green1};
`;

type TabItemProps = {
  isActive: boolean;
};

export const TabItem = styled.li<TabItemProps>`
  ${typography.buttonTextSmall}
  display: flex;
  align-items: center;
  border-radius: ${size.md}px;
  padding: ${size.md}px;
  background-color: ${({ isActive }) =>
    isActive ? colors.green4 : colors.green1};
  color: ${({ isActive }) => (isActive ? colors.white : colors.green4)};
  cursor: pointer;
`;
