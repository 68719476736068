import { Container } from '@everlywell/leaves';
import React, { useState } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { ContentSection, SectionBackgroundType } from 'utils/types';

import HowItWorksContainer from '../HowItWorksContainer';
import * as S from './HowItWorksWithTabs.styles';

export type HowItWorksWithTabsProps = {
  content: {
    title?: string;
    listOfContentSections?: ContentSection[];
    noNumberTags?: boolean;
    backgroundType?: SectionBackgroundType;
  };
};

/**
 * Wrapper for the HowItWorks + tabs
 */
export default function HowItWorksWithTabs({
  content,
}: HowItWorksWithTabsProps): JSX.Element | null {
  const {
    listOfContentSections: sections,
    title,
    noNumberTags,
    backgroundType,
  } = content;

  // First section is active
  const [aciveTab, setActiveTab] = useState(
    (sections && sections[0].id) || null,
  );

  if (!content.listOfContentSections) {
    return null;
  }

  const onTabCLick = (id: string, title: string) => {
    setActiveTab(id);
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.HOW_IT_WORKS_TAB_CLICK,
      data: {
        label: title,
      },
    });
  };

  return (
    <Container>
      <S.Container backgroundType={backgroundType}>
        <S.TopWrapper>
          <S.Title>{title}</S.Title>

          {/* TODO: create or modify the leaves tab component to have 
          tabs with round buttons/borders and horizontally scrollable */}
          <S.Tab>
            {sections?.map((section) => (
              <S.TabItem
                key={section.id}
                isActive={section.id === aciveTab}
                onClick={() => onTabCLick(section.id, section.title)}
              >
                {section.title}
              </S.TabItem>
            ))}
          </S.Tab>
        </S.TopWrapper>

        {sections?.map((section) => {
          if (section.id !== aciveTab && section.listOfContentSections) {
            return null;
          }

          return (
            <HowItWorksContainer
              key={section.id}
              content={{
                headline: section.headline,
                listOfContentSections: section.listOfContentSections,
                sectionId: section.title, // used for tracking visibility during scroll
                noNumberTags: noNumberTags,
              }}
            ></HowItWorksContainer>
          );
        })}
      </S.Container>
    </Container>
  );
}
